export const environment = {
  appVersion: 'local V ' + require('../../package.json').version,
  domainApi: 'dev-service.siamese-marketing.com',
  apiUrl: 'https://dev-service.siamese-marketing.com',
  production: false,
  authService: '/auth',
  roleService: '/roles',
  staffService: '/staff',
  marketingService: '/marketing',
  leadsService: '/lead',
};
